const en = {
    language: {
        de: "German",
        en: "English",
    },
    startpage: {
        seo: {
            title: "novation – Your Salesforce Partner #1",
            description: "novation is your #1 partner for insurance innovation with Saleforce. We reinvent Insurance Experience with the World's #1 CRM Solution.",
        },
        hero: {
            news: {
                more: "Read more",
            },
        },
        team: {
            employees: {
                you: {
                    name: "You",
                    title: "Next colleague",
                    email: "Email us",
                    career: "Career",
                },
            },
        },
        pricing: {
            packages: {
                type: "Package Type",
                individual: "Individual",
                package: "Package",
                personDays: "<0>Person</0><1>Days</1>",
                enquire: "Enquire this solution",
                from: "From",
            },
        },
        contact: {
            form: {
                firstname: "First name",
                lastname: "Last name",
                email: "Email",
                company: "Company",
                phone: "Phone",
                message: "Message",
                captcha: "Security Code",
                consent: "Consent",
                consentText: "I agree to the <1>privacy policy</1>.",
                submit: "Submit",
                sending: "sending...",
                required: "is required",
                incorrect: "is incorrect",
            }
        },
    },
    career: {
        seo: {
            title: "Career – novation",
            description: "Unlock your potential with us as your trusted Salesforce partner in the insurance sector. Join a dynamic team where innovation meets expertise.",
        },
    },
    imprint: {
        design: "Design and Implementation",
        seo: {
            title: "Imprint – novation",
        },
    },
    dataPrivacy: {
        seo: {
            title: "Data Privacy – novation",
        },
    },
    notfound: {
        seo: {
            title: "Page not found – novation",
        },
        error: {
            title: "Page not found",
            message: "Sorry 😔, we couldn't find what you were looking for.",
            link: "Go home",
        },
    },
    header: {
        navigation: {
            openMenu: "Open main menu",
            closeMenu: "Close menu",
            career: "Career",
            about: "About us",
            team: "Team",
            contact: "Contact",
        },
    },
    general : {
        email: "Email",
        linkedIn: "LinkedIn",
    },
}

export default en
