import React from "react"
import { Provider } from "react-redux"
import { SnackbarProvider } from "notistack"
import { store } from "../state/store"
import "../i18n/i18n"
import "../../node_modules/flag-icons/css/flag-icons.min.css";

const globalWrapper = ({ element }) => {
    return (
        <Provider store={store}>   
            <SnackbarProvider maxSnack={3} anchorOrigin={{horizontal: "right", vertical: "bottom"}} >
                {element}
            </SnackbarProvider>
        </Provider>
    )
}

export default globalWrapper
