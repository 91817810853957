import i18n from "i18next"
import { initReactI18next } from "react-i18next"
import LanguageDetector from 'i18next-browser-languagedetector'
import de from "./translations/de.i18n"
import en from "./translations/en.i18n"

i18n.use(initReactI18next)
    .use(LanguageDetector)
    .init({
        supportedLngs: ['de', 'en'],
        fallbackLng: 'en',
        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },
        resources: {
            de: {
                translation: de,
            },
            en: {
                translation: en,
            }
        },
    })

export default i18n
