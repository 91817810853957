const de = {
    language: {
        de: "Deutsch",
        en: "Englisch",
    },
    startpage: {
        seo: {
            title: "novation – Dein Salesforce-Partner #1",
            description: "novation ist Ihr Partner #1 für Innovationen im Versicherungswesen mit Salesforce. Wir gestalten das Versicherungserlebnis mit der weltweit führenden CRM-Lösung neu.",
        },
        hero: {
            news: {
                more: "Mehr erfahren",
            },
        },
        team: {
            employees: {
                you: {
                    name: "Du",
                    title: "Neuer Kollege/in",
                    email: "Schreib uns!",
                    career: "Karriere",
                },
            },
        },
        pricing: {
            packages: {
                type: "Paket-Art",
                individual: "Individuell",
                package: "Pauschal",
                personDays: "<0>Personen</0><1>Tage</1>",
                enquire: "Jetzt anfragen",
                from: "Ab",
            },
        },
        contact: {
            form: {
                firstname: "Vorname",
                lastname: "Nachname",
                email: "E-Mail",
                company: "Firma",
                phone: "Telefon",
                message: "Nachricht",
                captcha: "Sicherheitscode",
                consent: "Zustimmung",
                consentText: "Ich stimme der <1>Datenverarbeitung</1> zu.",
                submit: "Absenden",
                sending: "sendet...",
                required: "wird benötigt",
                incorrect: "ist nicht korrekt",
            }
        },
    },
    career: {
        seo: {
            title: "Karriere – novation",
            description: "Entfalte dein Potenzial mit uns als deinem vertrauenswürdigen Salesforce-Partner in der Versicherungsbranche. Werde Teil eines dynamischen Teams, in dem Innovation auf Expertise trifft.",
        },
    },
    imprint: {
        design: "Design und Umsetzung"
    },
    dataPrivacy: {
        seo: {
            title: "Datenschutz – novation",
        },
    },
    notfound: {
        seo: {
            title: "Seite nicht gefunden – novation",
        },
        error: {
            title: "Seite nicht gefunden",
            message: "Es tut uns leid 😔, aber wir konnten die aufgerufene Seite nicht finden.",
            link: "Zurück zur Startseite",
        },
    },
    header: {
        navigation: {
            openMenu: "Menü öffnen",
            closeMenu: "Menü schließen",
            career: "Karriere",
            about: "Über uns",
            team: "Team",
            contact: "Kontakt",
        },
    },
    general : {
        email: "E-Mail",
        linkedIn: "LinkedIn",
    },
}

export default de
